<template>
  <a-layout>
    <a-layout-header :style="{ position: 'fixed', zIndex: 999, width: '100%' }">
      <div class="nav-header-main">
        <div class="nav-header-left">
          <div class="logo">
            <a href="">
              <img src="@/assets/images/logo.png" alt="" />
              <span class="title"> 素材库</span>
            </a>
          </div>
        </div>
        <!-- theme="dark" -->
        <div>
          <a-menu v-model="selectedKeys" mode="horizontal" :style="{ lineHeight: '64px' }">
            <a-menu-item key="1">
              <template #icon> <appstore-outlined /> </template>
              <router-link to="/"> 首页 </router-link>
            </a-menu-item>
            <a-menu-item key="2">
              <template #icon> <appstore-outlined /> </template>
              <router-link to="/resources">资源</router-link>
            </a-menu-item>
            <a-menu-item key="3">
              <template #icon> <appstore-outlined /> </template>
              <router-link to="/wish">世界</router-link>
            </a-menu-item>
          </a-menu>
        </div>
        <div></div>
      </div>
    </a-layout-header>
    <a-layout class="layout-content" style="margin-left: 200px">
      <a-layout-sider class="ant-pro-sider-fixed" width="200" style="
          max-height: 100vh;
          background: #fff;
          padding-top: 74px;
          overflow-y: auto;
        ">
        <div style="flex: 1 1 0%; overflow: hidden auto">
          <a-menu v-model="openKeys" mode="inline" :style="{ height: '100%', borderRight: 0 }">
            <a-sub-menu key="sub1">
              <template #title>
                <span>
                  <user-outlined />
                  subnav 1
                </span>
              </template>
              <a-menu-item key="1">option1</a-menu-item>
              <a-menu-item key="2">option2</a-menu-item>
              <a-menu-item key="3">option3</a-menu-item>
              <a-menu-item key="4">option4</a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="sub2">
              <template #title>
                <span>
                  <laptop-outlined />
                  subnav 2
                </span>
              </template>
              <a-menu-item key="5">option5</a-menu-item>
              <a-menu-item key="6">option6</a-menu-item>
              <a-menu-item key="7">option7</a-menu-item>
              <a-menu-item key="8">option8</a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="sub3">
              <template #title>
                <span>
                  <notification-outlined />
                  subnav 3
                </span>
              </template>
              <a-menu-item key="9">option9</a-menu-item>
              <a-menu-item key="10">option10</a-menu-item>
              <a-menu-item key="11">option11</a-menu-item>
              <a-menu-item v-for="(item, index) in list" :key="index">option12</a-menu-item>
            </a-sub-menu>
          </a-menu>
        </div>
      </a-layout-sider>
      <a-layout style="padding: 0 0px 0px">
        <a-layout-content style="margin-top: 64px">
          <router-view />
        </a-layout-content>
        <a-layout-footer :style="{ textAlign: 'center' }">
          Ant Design ©2018 Created by Ant UED
        </a-layout-footer>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import { AppstoreOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
export default defineComponent({
  components: {
    AppstoreOutlined,
  },
  setup() {
    return {
      list: [1, 2, 3, 4, 5, 6, 5, 4],
      selectedKeys1: ref(["2"]),
      selectedKeys2: ref(["1"]),
      collapsed: ref(false),
      openKeys: ref(["sub1"]),
    };
  },
});
</script>
<style lang="less"  scoped>
.ant-layout {
  height: 100% // height: calc(~"100% - 65px");
}

.layout-content {
  height: 100%;

  // height: calc(~"100% - 65px");
}

.layout-content {
  overflow: auto;
}

.ant-layout-content {
  overflow: auto;
}

.ant-pro-sider-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
}
</style>